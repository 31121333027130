import { Form } from 'react-bootstrap'
import styled, { css } from 'styled-components'
import { ChevronDownIcon } from '../Icons/ChevronDownIcon'
import { primaries, neutrals, reds, greens } from '../../../assets/colors'

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`

export const Label = styled(Form.Label)<{
  $invalid?: boolean
  $disabled?: boolean
  $large?: boolean
}>(
  ({ theme, $invalid, $disabled, $large }) => css`
    color: ${primaries.P500};
    font-size: 12px;
    left: 16px;
    letter-spacing: 0.005em;
    margin: 0;
    pointer-events: none;
    position: absolute;
    text-transform: uppercase;
    top: 8px;

    ${$invalid &&
    css`
      color: ${reds.R500};
    `}

    ${$disabled &&
    css`
      color: ${primaries.P200};
    `}

    // When the placeholder is an empty string and no option is selected, make
    // the label larger and center it. This matches an empty TextField's style.
    ${$large &&
    css`
      font-size: 17px;
      font-weight: 300;
      top: initial;
    `}
  `,
)

export const Input = styled(Form.Control)<{
  $label?: boolean
  $hideText?: boolean
}>(
  ({ theme, $label, $hideText }) => css`
    appearance: none;
    background: ${primaries.P00};
    border-color: ${primaries.P00};
    color: ${primaries.P900};
    font-size: 16px;
    font-weight: 300;
    height: 56px;
    letter-spacing: 0.005em;
    padding: ${$label ? '24px 16px 8px 16px' : '16px'};
    -moz-padding-start: 11px; /* Fixes alignment in Firefox. */

    &:hover {
      background-color: ${primaries.P50};
    }

    &:focus {
      background-color: ${primaries.P50};
      border-color: ${primaries.P800};
    }

    &:disabled {
      background: ${primaries.P00};
      color: ${primaries.P200};
    }

    &.is-valid,
    &.is-invalid {
      background-color: ${neutrals.N000};
      background-position: right 48px center;

      &:focus {
        box-shadow: none;
      }
    }

    &.is-valid {
      border-color: ${greens.G600};
    }

    &.is-invalid {
      border-color: ${reds.R500};
    }

    ${$hideText &&
    css`
      &,
      &:focus,
      &:disabled {
        color: transparent;
      }

      option {
        color: ${primaries.P900};
      }
    `}
  `,
)

export const Chevron = styled(ChevronDownIcon)`
  position: absolute;
  right: 16px;
  top: 20px;
  pointer-events: none;
`

export const Note = styled.span<{ $error?: boolean }>(
  ({ $error }) => css`
    color: ${$error ? reds.R500 : '#898989'};
    padding-left: 16px;
    padding-top: 4px;
    font-size: 18px;
  `,
)
