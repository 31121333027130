import { styled } from '@mui/material/styles'
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector'

export const StepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean }
}>(({ ownerState }) => ({
  backgroundColor: '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative', // Add position relative

  ...(ownerState.active && {
    backgroundImage: 'linear-gradient( 136deg,#238c4e 0%, #2cda5f 50%, #6bed81 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage: 'linear-gradient( 136deg,#238c4e 0%, #2cda5f 50%, #6bed81 100%)',
  }),
}))

export const Connector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: 'linear-gradient(136deg, #238c4e 0%, #2cda5f 50%, #6bed81 100%)',
      opacity: 1, // Fully visible on active
      transition: 'opacity 1.2s ease-in-out', // Add transition for opacity
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: 'linear-gradient(136deg, #238c4e 0%, #2cda5f 50%, #6bed81 100%)',
      opacity: 1, // Fully visible for completed steps
      transition: 'opacity 1.2s ease-in-out', // Add transition for opacity
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    background: 'linear-gradient(90deg, #ccc, #ccc)', // Default gray connector
    borderRadius: 1,
    opacity: 0.3, // Set initial opacity for inactive steps
    transition: 'opacity 1.2s ease-in-out', // Add transition for opacity
  },
}))
