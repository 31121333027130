import { FunctionComponent } from 'react'
import { CheckBox, Root } from './FormSteps.styled'
import TextField from '../../Shared/TextField/TextField'
import { FormikProps, FormikValues } from 'formik'
import { Note } from '../../Shared/TextField/TextField.styled'

const SchoolSection: FunctionComponent<FormikProps<FormikValues>> = ({
  values,
  handleBlur,
  handleChange,
  errors,
  touched,
}) => {
  return (
    <Root spacing='large'>
      <TextField
        name='gradYear'
        label='High School Graduation Year'
        value={values.gradYear}
        onBlur={handleBlur}
        onChange={handleChange}
        isInvalid={touched.gradYear && !!errors.gradYear}
        errorText={errors.gradYear}
      />
      <TextField
        name='currentSchool'
        label='Current School'
        value={values.currentSchool}
        onBlur={handleBlur}
        onChange={handleChange}
        isInvalid={touched.currentSchool && !!errors.currentSchool}
        errorText={errors.currentSchool}
      />
      <TextField
        name='committedSchool'
        label='College Commitment'
        value={values.committedSchool}
        onBlur={handleBlur}
        onChange={handleChange}
        isInvalid={touched.committedSchool && !!errors.committedSchool}
        errorText={errors.committedSchool}
        helpText='Where are you committed to play in college? If not, fill out as N/A'
      />
      <TextField
        name='clubTeam'
        label='Club Team'
        value={values.clubTeam}
        onBlur={handleBlur}
        onChange={handleChange}
        isInvalid={touched.clubTeam && !!errors.clubTeam}
        errorText={errors.clubTeam}
        helpText='What club do you play for? If none, fill out as N/A'
      />
      <div id='checkbox-div' style={{ display: 'flex', alignItems: 'center' }}>
        <CheckBox
          type='checkbox'
          name='trainedWithUsBefore'
          checked={values.trainedWithUsBefore}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        <Note style={{ paddingTop: 0 }}>Check this box if you have trained with us before!</Note>
      </div>
      <TextField
        name='trainingLocation'
        label='Location'
        helpText='If you checked the box above, which location was it? You can leave this blank if you have not trained with us yet.'
        value={values.trainingLocation}
        onBlur={handleBlur}
        onChange={handleChange}
        isInvalid={touched.trainingLocation && !!errors.trainingLocation}
        errorText={errors.trainingLocation}
      />
    </Root>
  )
}

export default SchoolSection
