import React, { FunctionComponent } from 'react'
import { Chevron, FieldWrapper, Input, Label, Note, Root } from './Select.styled'

const placeholderValue = '__PLACEHOLDER__'

export interface SelectOption {
  label: string
  value: string
}

export interface SelectProps {
  name: string
  options: SelectOption[]
  className?: string
  disabled?: boolean
  /** Shown when isInvalid is true, replaces help text. */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorText?: any
  helpText?: string
  isInvalid?: boolean
  isValid?: boolean
  label?: string
  onBlur?: React.FocusEventHandler<HTMLSelectElement>
  onChange?: React.ChangeEventHandler<HTMLSelectElement>
  /** Selects the option with a matching value. */
  value?: string
}

const Select: FunctionComponent<SelectProps> = ({
  label,
  name,
  options,
  disabled,
  helpText,
  errorText,
  isInvalid,
  isValid,
  onBlur,
  onChange,
  value,
  className,
}) => {
  const showErrorText = errorText && isInvalid

  return (
    <Root className={className}>
      <FieldWrapper>
        {label && (
          <Label $invalid={isInvalid} $disabled={disabled} $large={!value}>
            {label}
          </Label>
        )}
        <Input
          name={name}
          isValid={isValid}
          onBlur={onBlur}
          onChange={onChange}
          forwardedAs='select'
          value={value || placeholderValue}
          disabled={disabled}
          $label={!!label}
          $hideText={!value}
        >
          <option value={placeholderValue} disabled>
            Select an option...
          </option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Input>
        <Chevron />
      </FieldWrapper>
      {!showErrorText && helpText && <Note>{helpText}</Note>}
      {showErrorText && <Note $error>{errorText}</Note>}
    </Root>
  )
}

export default Select
