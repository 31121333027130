import { FunctionComponent } from 'react'
import StepIcon from './StepIcon'
import { Connector } from './Stepper.styled'
import { Stepper as MuiStepper } from '@mui/material'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'

const steps = ['Select campaign settings', 'Create an ad group', 'Create an ad']

interface StepperProps {
  activeStep: number
}

const Stepper: FunctionComponent<StepperProps> = ({ activeStep }) => {
  return (
    <MuiStepper alternativeLabel activeStep={activeStep} connector={<Connector />}>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel StepIconComponent={StepIcon} />
        </Step>
      ))}
    </MuiStepper>
  )
}

export default Stepper
