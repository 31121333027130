import { FunctionComponent } from 'react'
import { Root } from './FormSteps.styled'
import TextField from '../../Shared/TextField/TextField'
import { FormikProps, FormikValues } from 'formik'

const heightLabel = 'Height... Exmaple: 5\'9"'

const PersonalSection: FunctionComponent<FormikProps<FormikValues>> = ({
  values,
  handleBlur,
  handleChange,
  errors,
  touched,
}) => {
  return (
    <Root spacing='large'>
      <TextField
        name='parentEmail'
        label='Parent Email'
        value={values.parentEmail}
        onBlur={handleBlur}
        onChange={handleChange}
        isInvalid={touched.parentEmail && !!errors.parentEmail}
        errorText={errors.parentEmail || ''}
      />
      <TextField
        name='parentName'
        label='Parent Name'
        value={values.parentName}
        onBlur={handleBlur}
        onChange={handleChange}
        isInvalid={touched.parentName && !!errors.parentName}
        errorText={errors.parentName}
      />
      <TextField
        name='playerEmail'
        label='Player Email'
        value={values.playerEmail}
        onBlur={handleBlur}
        onChange={handleChange}
        isInvalid={touched.playerEmail && !!errors.playerEmail}
        errorText={errors.playerEmail}
      />
      <TextField
        name='playerName'
        label='Player Name'
        value={values.playerName}
        onBlur={handleBlur}
        onChange={handleChange}
        isInvalid={touched.playerName && !!errors.playerName}
        errorText={errors.playerName}
      />
      <TextField
        name='height'
        label={heightLabel}
        value={values.height}
        onBlur={handleBlur}
        onChange={handleChange}
        isInvalid={touched.height && !!errors.height}
        errorText={errors.height}
      />
      <TextField
        name='weight'
        label='Weight in lbs'
        value={values.weight}
        onBlur={handleBlur}
        onChange={handleChange}
        isInvalid={touched.weight && !!errors.weight}
        errorText={errors?.weight}
      />
      <TextField
        name='zipCode'
        label='Zip Code'
        value={values.zipCode}
        onBlur={handleBlur}
        onChange={handleChange}
        isInvalid={touched.zipCode && !!errors.zipCode}
        errorText={errors.zipCode}
      />
    </Root>
  )
}

export default PersonalSection
