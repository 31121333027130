import { useState } from 'react'
import { Root, ButtonDiv, LaxLabButton } from './UserForm.styled'
import { Form, FormikProps, FormikValues } from 'formik'
import PersonalSection from './FormSteps/PersonalSection'
import SchoolSection from './FormSteps/SchoolSection'
import LaxSection from './FormSteps/LaxSection'
import { submitForm } from '../../api/submitForm'
import { formValidator, initialValues, cantContinue } from './formUtil'
import { CircularProgress } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Stepper from './Stepper/Stepper'

const UserForm = () => {
  const [step, setStep] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const navigate = useNavigate()

  // Choose which form step to render
  const renderStep = (props: FormikProps<FormikValues>) => {
    switch (step) {
      case 0:
        return <PersonalSection {...props} />
      case 1:
        return <SchoolSection {...props} />
      case 2:
        return <LaxSection {...props} />
      default:
        // If for some reason things are screwed up.. reset form
        setStep(0)
        break
    }
  }

  const handleSubmitForm = async (values: FormikValues) => {
    await submitForm(values)
  }

  return (
    <>
      <div className='mb-5'>
        <Stepper activeStep={step} />
      </div>
      <Root
        initialValues={initialValues}
        validationSchema={formValidator}
        onSubmit={handleSubmitForm}
      >
        {(formikProps: FormikProps<FormikValues>) => (
          <Form
            onSubmit={async (e) => {
              e.preventDefault()
              setIsLoading(true)
              await submitForm(formikProps.values)
              setIsLoading(false)
              formikProps.resetForm()
              setStep(0)
              navigate('/')
            }}
          >
            {renderStep(formikProps)}

            <ButtonDiv>
              {step > 0 && (
                <LaxLabButton variant='contained' size='large' onClick={() => setStep(step - 1)}>
                  Back
                </LaxLabButton>
              )}
              {step < 2 && (
                <LaxLabButton
                  disabled={cantContinue(step, formikProps)}
                  variant='contained'
                  size='large'
                  onClick={() => setStep(step + 1)}
                >
                  Next
                </LaxLabButton>
              )}
              {step === 2 && (
                <LaxLabButton
                  size='large'
                  disabled={!formikProps.isValid || isLoading}
                  variant='contained'
                  type='submit'
                >
                  {isLoading ? (
                    <CircularProgress style={{ width: '26.5px', height: '26.5px' }} />
                  ) : (
                    'Submit'
                  )}
                </LaxLabButton>
              )}
            </ButtonDiv>
          </Form>
        )}
      </Root>
    </>
  )
}

export default UserForm
