import * as yup from 'yup'
import { FormikProps, FormikValues } from 'formik'

export enum Position {
  ATTACK = 'attack',
  MIDFIELD = 'midfield',
  BOTH = 'both',
  UNSELECTED = '',
}

export enum PlayStyle {
  SHIFTY_ATTACKMAN = 'Shifty Attackman',
  POWERFUL_ATTACKMAN = 'Powerful Attackman',
  FAST_ATTACKMAN = 'Fast AttackMan',
  OFF_BALL_SHOOTER_ATTACKMAN = 'Off Ball/Shooter Attackman',
  BALANCED_ATTACKMAN = 'Balanced Attackman',
  FAST_MIDFIELDER = 'Fast Midfielder',
  POWERFUL_MIDFIELDER = 'Powerful Midfielder',
  OFF_BALL_SHOOTER_MIDFIELDER = 'Off Ball/Shooter Midfielder',
  BALANCED_MIDFIELDER = 'Balanced Midfielder',
  UNSELECTED = '',
}

export enum DevelopmentGoal {
  X_ATTACKMAN_SKILLS = 'X Attackman Skills',
  WING_INSIDE_ATTACKMAN_SKILLS = 'Wing/Inside Attackman Skills',
  ALL_AROUND_ATTACK_SKILLS = 'All-Around Attack Skills',
  MIDFIELD_DODGING_SKILLS = 'Midfield Dodging Skills',
  MIDFIELD_SHOOTING_SKILLS = 'Midfield Shooting Skills',
  ALL_AROUND_MIDFIELD_SKILLS = 'All-Around Midfield Skills',
  UNSELECTED = '',
}

export enum Feedback {
  DODGING = 'Dodging',
  SHOOTING = 'Shooting',
  FOOTWORK = 'Footwork',
  UNSELECTED = '',
}

export type FormType = {
  parentName: string
  parentEmail: string
  playerName: string
  playerEmail: string
  height: string
  weight: string
  zipCode: string
  gradYear: string
  currentSchool: string
  committedSchool: string
  clubTeam: string
  position: Position
  playStyle: PlayStyle
  developmentGoal: DevelopmentGoal
  feedback: Feedback
  trainingLocation: string
  trainedWithUsBefore: boolean
}

export const initialValues: FormType = {
  parentName: '',
  parentEmail: '',
  playerName: '',
  playerEmail: '',
  height: '',
  weight: '',
  zipCode: '',
  gradYear: '',
  currentSchool: '',
  committedSchool: '',
  clubTeam: '',
  position: Position.UNSELECTED,
  playStyle: PlayStyle.UNSELECTED,
  developmentGoal: DevelopmentGoal.UNSELECTED,
  feedback: Feedback.UNSELECTED,
  trainingLocation: '',
  trainedWithUsBefore: false,
}

// TODO: fix validations for string numbers
const requiredString = yup.string().required('Required')
const naString = yup.string().required('Required. Use N/A if not applicable')
const heightSchema = yup.string().matches(/^\d{1,}'\d{1,2}"$/, 'Invalid height format')
const requiredEmail = yup.string().email('Must be a valid email address.').required('Required')
const zipCodeSchema = yup.string().matches(/^\d{5}(?:-\d{4})?$/, 'Invalid ZIP code format')
const gradyYearSchema = yup
  .string()
  .matches(/^\d*$/, 'Must be a valid graduation year') // Ensure it only contains digits
  .min(4, 'Must have exactly 4 digits')
  .max(4, 'Must have exactly 4 digits')
  .required('This field is required')

export const formValidator = yup.object({
  parentName: requiredString,
  parentEmail: requiredEmail,
  playerName: requiredString,
  playerEmail: requiredEmail,
  height: requiredString,
  weight: requiredString,
  zipCode: zipCodeSchema,
  gradYear: gradyYearSchema,
  currentSchool: requiredString,
  committedSchool: naString,
  clubTeam: naString,
  position: requiredString,
  playStyle: requiredString,
  developmentGoal: requiredString,
  feedback: requiredString,
})

export const cantContinue = (step: number, formikProps: FormikProps<FormikValues>): boolean => {
  const { errors, dirty } = formikProps
  switch (step) {
    case 0:
      if (
        !dirty ||
        errors.parentEmail ||
        errors.parentName ||
        errors.playerName ||
        errors.playerEmail ||
        errors.height ||
        errors.weight ||
        errors.zipCode
      )
        return true
      else return false

    case 1:
      if (
        !dirty ||
        errors.gradYear ||
        errors.currentSchool ||
        errors.committedSchool ||
        errors.clubTeam
      )
        return true
      else return false
    case 2:
      if (
        !dirty ||
        errors.position ||
        errors.playStyle ||
        errors.developmentGoal ||
        errors.feedback
      )
        return true
      else return false
    default:
      return true
  }
}
