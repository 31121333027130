const enum COLORS {
  GRAY = '#898989',
  BLACK = '#1a1a1a',
  GREEN = '#2cda5f',
}

export const primaries = {
  P900: '#192b34',
  P800: '#2a404b',
  P700: '#375260',
  P600: '#456676',
  P500: '#517486',
  P400: '#6a8898',
  P300: '#839dab',
  P200: '#a5b9c4',
  P100: '#c6d6dd',
  P50: '#e6eef5',
  P00: '#f5f8fb',
}

export const neutrals = {
  N1000: '#000000',
  N000: '#ffffff',
}

export const reds = {
  R900: '#82211f',
  R800: '#ab3d3c',
  R700: '#bc4542',
  R600: '#cb4c45',
  R500: '#c95959',
  R400: '#c37476',
  R300: '#d5999b',
  R200: '#eeccd1',
  R100: '#f8eaed',
}

export const greens = {
  G900: '#276036',
  G800: '#377c49',
  G700: '#479f60',
  G600: '#4fae6a',
  G500: '#68ba7f',
  G400: '#82c695',
  G300: '#a5d5b3',
  G200: '#c8e6d0',
  G100: '#e8f5ec',
}

export default COLORS