import api from './api'
import * as endpoints from './endpoints'
import { toast } from 'react-toastify'

export const submitForm = async (formSubmission: unknown) => {
  try {
    await api.post(endpoints.FormSubmission, formSubmission)
    toast.success('Please check your email for the link to your custom program!', {
      autoClose: false
    })
  } catch (error) {
    toast.error('Error in submitting your form, please email us directly at drills@thelaxlab.com', {
      autoClose: false,
    })
    // TODO: add specific messaging here if we want
    console.log(error) // Log this for server monitoring
  }
}
