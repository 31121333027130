import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import muiTheme from './config/muiTheme'
import { ToastContainer } from 'react-toastify'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-toastify/dist/ReactToastify.css'
import { ThemeProvider } from '@mui/material'

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={muiTheme}>
      <ToastContainer theme='colored' position='top-right' autoClose={1500} />
      <BrowserRouter>
        <Routes>
          <Route path='/*' element={<App />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)
