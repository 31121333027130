import React, { FunctionComponent, ReactNode } from 'react'
import { Root, StackDirection, StackSpacing } from './Stack.styled'

export interface StackProps {
  className?: string
  direction?: StackDirection
  spacing?: StackSpacing
  children: ReactNode
}

const Stack: FunctionComponent<StackProps> = ({
  direction = 'vertical',
  spacing = 'medium',
  className,
  children,
}) => {
  return (
    <Root $direction={direction} $spacing={spacing} className={className}>
      {children}
    </Root>
  )
}

export default Stack