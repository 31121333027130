import { FunctionComponent, ReactNode } from 'react'
import { Root } from './Icon.styled'

export interface IconProps {
  className?: string
  size?: number
  viewBox?: number
  children?: ReactNode
}

export const Icon: FunctionComponent<IconProps> = ({ children, className, size = 20 }) => (
  <Root $size={size} className={className} viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
    {children}
  </Root>
)
