import { FunctionComponent } from 'react'
import { Root } from './FormSteps.styled'
import { DEVELOPMENT_GOAL, PLAY_STYLE, POSITION } from '../../../util/enums'
import { FormikProps, FormikValues } from 'formik'
import Select from '../../Shared/Select/Select'

const positions: { [K in POSITION]: string } = {
  attack: 'Attack',
  midfield: 'Midfield',
  both: 'Both',
}

const playStyle: { [K in PLAY_STYLE]: string } = {
  shiftyAttackman: 'Shifty Attackman',
  powerfulAttackman: 'Powerful Attackman',
  fastAttackman: 'Fast AttackMan',
  shooterAttackman: 'Off Ball/Shooter Attackman',
  balancedAttackman: 'Balanced Attackman',
  fastMidfielder: 'Fast Midfielder',
  powerfulMidfielder: 'Powerful Midfielder',
  shooterMidfielder: 'Off Ball/Shooter Midfielder',
  balancedMidfielder: 'Balanced Midfielder',
}

const developmentGoal: { [K in DEVELOPMENT_GOAL]: string } = {
  xAttackmanSkills: 'X Attackman Skills',
  wingInsideAttackmanSkills: 'Wing/Inside Attackman Skills',
  allAroundAttackSkills: 'All-Around Attack Skills',
  midfieldDodgingSkills: 'Midfield Dodging Skills',
  midfieldShootingSkills: 'Midfield Shooting Skills',
  allAroundMidfieldSkills: 'All-Around Midfield Skills',
}

const feedback = {
  dodging: 'Dodging',
  shooting: 'Shooting',
  footwork: 'Footwork',
}

const LaxSection: FunctionComponent<FormikProps<FormikValues>> = ({
  values,
  handleBlur,
  handleChange,
  errors,
  touched,
  submitCount,
}) => {
  return (
    <Root spacing='large'>
      <Select
        name='position'
        label='Position'
        value={values.position}
        options={Object.entries(positions).map(([key, value]) => ({
          label: value,
          value: key,
        }))}
        onBlur={handleBlur}
        onChange={handleChange}
        isInvalid={(submitCount > 0 || touched.position) && !!errors.position}
        errorText={errors.position}
        helpText='What is your primary position?'
      />
      <Select
        name='playStyle'
        label='Play Style'
        value={values.playStyle}
        options={Object.entries(playStyle).map(([key, value]) => ({
          label: value,
          value: key,
        }))}
        onBlur={handleBlur}
        onChange={handleChange}
        isInvalid={(submitCount > 0 || touched.playStyle) && !!errors.playStyle}
        errorText={errors.playStyle}
        helpText='What style does your game most likely reflect?'
      />
      <Select
        name='developmentGoal'
        label='Development Goal'
        value={values.developmentGoal}
        options={Object.entries(developmentGoal).map(([key, value]) => ({
          label: value,
          value: key,
        }))}
        onBlur={handleBlur}
        onChange={handleChange}
        isInvalid={(submitCount > 0 || touched.developmentGoal) && !!errors.developmentGoal}
        errorText={errors.developmentGoal}
        helpText='What are you looking to develop most out of Lax Lab?'
      />
      <Select
        name='feedback'
        label='Feedback'
        value={values.feedback}
        options={Object.entries(feedback).map(([key, value]) => ({
          label: value,
          value: key,
        }))}
        onBlur={handleBlur}
        onChange={handleChange}
        isInvalid={(submitCount > 0 || touched.feedback) && !!errors.feedback}
        errorText={errors.feedback}
        helpText='Based on the feedback from your coaches, teammates, and self-reflecting, what skill do you think you need to develop most?'
      />
    </Root>
  )
}

export default LaxSection
