import { Route, Routes, Navigate } from 'react-router-dom'
import { Root } from './App.styled'
import Home from './components/Home/Home'
import FormPage from './components/FormPage'

function App() {
  return (
    <Root>
      <Routes>
        <Route index element={<Home />} />
        <Route path='form' element={<FormPage />} />
        {/* Catch other routes and send them to correct page. TODO: add 404 page */}
        <Route path='*' element={<Navigate to='/' />} />
      </Routes>
    </Root>
  )
}

export default App
