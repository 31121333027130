import axios from 'axios'

const API_BASE_URL = process.env.REACT_APP_BASE_API_URL // Replace with your API base URL

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    // Add any other headers you need
  },
})

export default api
