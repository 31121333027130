import { FunctionComponent } from 'react'
import { Icon, IconProps } from './Icon'

export const CheckIcon: FunctionComponent<IconProps> = ({ className, size }) => (
  <Icon className={className} size={size}>
    <path
      fillRule='evenodd'
      d='M10 20C15.523 20 20 15.523 20 10C20 4.477 15.523 0 10 0C4.477 0 0 4.477 0 10C0 15.523 4.477 20 10 20ZM9.429 12.99L14.179 8.24001C14.519 7.90001 14.519 7.35 14.179 7C13.839 6.66 13.279 6.66 12.939 7L8.80901 11.13L7.05901 9.38C6.71901 9.04 6.159 9.04 5.819 9.38C5.479 9.72001 5.479 10.27 5.819 10.62L8.19901 12.99C8.36901 13.16 8.58901 13.24 8.80901 13.24C9.03901 13.24 9.259 13.16 9.429 12.99Z'
      fill='currentColor'
    />
  </Icon>
)
