// @ts-nocheck
import Cleave from 'cleave.js/react'
import type { CleaveOptions } from 'cleave.js/options'
import React, { FunctionComponent } from 'react'
import { Input, InvalidIcon, Label, Note, Root, ValidIcon } from './TextField.styled'
import { FormikErrors } from 'formik'

type MaskType = 'date' | 'money' | 'ssn' | 'integer'

const maskOptions: {
  [mask in MaskType]: { options: CleaveOptions; placeholder?: string }
} = {
  date: {
    placeholder: 'MM/DD/YYYY',
    options: {
      date: true,
      datePattern: ['m', 'd', 'Y'],
      delimiter: '/',
    },
  },
  money: {
    placeholder: '$0.00',
    options: {
      noImmediatePrefix: true,
      prefix: '$',
      numeral: true,
      numeralPositiveOnly: true,
      numeralThousandsGroupStyle: 'thousand',
      rawValueTrimPrefix: true,
    },
  },
  ssn: {
    placeholder: 'XXX-XX-XXXX',
    options: {
      delimiters: ['-', '-'],
      numericOnly: true,
      blocks: [3, 2, 4],
    },
  },
  integer: {
    options: {
      numericOnly: true,
    },
  },
}
export interface TextFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string
  /** Shown if `isInvalid` is true. Takes precedence over `helpText`. */
  errorText?: string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined
  helpText?: string
  isInvalid?: boolean
  isValid?: boolean
  label?: string
  /** Provides a placeholder and restricts the input. */
  mask?: MaskType
  placeholder?: string
}

const TextField: FunctionComponent<TextFieldProps> = ({
  label,
  className,
  disabled,
  errorText,
  helpText,
  isInvalid = false,
  isValid = false,
  placeholder,
  mask,
  value,
  onChange,
  ...rest
}: TextFieldProps) => {
  const showErrorText = errorText && isInvalid
  const hasPlaceholder = placeholder && placeholder !== ''

  // These props were inherited from the bootstrap-react component, where it's
  // possible for the field to be both valid and invalid. It would be better to
  // take a single validity prop that's either true, false, or undefined/null.
  if (isValid && isInvalid) {
    isValid = false
  }

  return (
    <Root className={className}>
      <Input
        $valid={isValid}
        $invalid={isInvalid}
        $placeholder={hasPlaceholder || !!mask}
        $label={label}
      >
        <Cleave
          value={value}
          disabled={disabled}
          // Styling relies a placeholder, even if it's an empty space.
          placeholder={hasPlaceholder ? placeholder : ' '}
          // When no mask is used, prevent Cleave from removing spaces.
          options={{
            delimiter: '',
            blocks: [9999999],
          }}
          onChange={(event) => {
            // For dollar amounts we want the unformatted value. For example,
            // '6543.21' instead of '$6,543.21'.
            if (mask === 'money') {
              event.target.value = event.target.rawValue

              // When working with money values, a consumer of TextField will
              // likely use parseFloat and get exponential notation for very
              // large numbers. This sets a maximum value for the callback.
              if (parseFloat(event.target.value) > 999999999999999) {
                return
              }
            }
            if (onChange) {
              onChange(event)
            }
          }}
          {...(mask && maskOptions[mask])}
          {...rest}
        />
        <Label $isInvalid={isInvalid} $isDisabled={disabled}>
          {label}
        </Label>
        {isValid && <ValidIcon />}
        {isInvalid && <InvalidIcon />}
      </Input>
      {!showErrorText && helpText && <Note>{helpText}</Note>}
      {showErrorText && <Note $hasError>{errorText}</Note>}
    </Root>
  )
}

export default TextField
