import styled, { css } from 'styled-components'

export type StackDirection = 'vertical' | 'horizontal'
export type StackSpacing = 'xXSmall' | 'xSmall' | 'small' | 'medium' | 'large' | number

const spacingSizes = (spacing: StackSpacing) => {
  switch (spacing) {
    case 'xXSmall':
      return '4px'
    case 'xSmall':
      return '8px'
    case 'small':
      return '12px'
    case 'medium':
      return '16px'
    case 'large':
      return '24px'
    default:

  }
}

export const Root = styled.div<{
  $direction: StackDirection
  $spacing: StackSpacing
}>(({ $direction, $spacing }) => {
  let gap: string
  if (typeof $spacing === 'number') {
    gap = `${$spacing}px`
  } else {
    gap = spacingSizes($spacing) || '16px'
  }

  return css`
    display: flex;
    flex-direction: ${$direction === 'vertical' ? 'column' : 'row'};
    width: 100%;

    /* The flexbox gap property would be better, but as of 04/2021 it is not
    supported by the browser that Chromatic uses (Chrome 84 on Linux). */
    & > *:not(:last-child) {
      margin: ${$direction === 'vertical' ? `0 0 ${gap} 0` : `0 ${gap} 0 0`};
    }
  `
})
