import { createTheme } from '@mui/material/styles'

const customTheme = createTheme({
  palette: {
    primary: {
      main: '#2cda5f', // Lax Lab Green
      dark: '#1e9644', // A darker or disabled-looking green
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            backgroundColor: '#1e9644', // Set the dark color for the disabled state
          },
        },
      },
    },
  },
})

export default customTheme
