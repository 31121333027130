import { FunctionComponent } from 'react'
import { Icon, IconProps } from './Icon'

export const ChevronDownIcon: FunctionComponent<IconProps> = ({ className, size }) => (
  <Icon className={className} size={size}>
    <path
      d='M2.24075 6.24576C2.53275 5.94776 2.99076 5.92077 3.31276 6.16477L3.40475 6.24576L9.99975 12.9728L16.5948 6.24576C16.8868 5.94776 17.3437 5.92077 17.6667 6.16477L17.7588 6.24576C18.0508 6.54376 18.0777 7.00975 17.8387 7.33875L17.7588 7.43278L10.5818 14.7538C10.2898 15.0518 9.83275 15.0788 9.50975 14.8348L9.41775 14.7538L2.24075 7.43278C1.91975 7.10478 1.91975 6.57376 2.24075 6.24576Z'
      fill='currentColor'
    />
  </Icon>
)
