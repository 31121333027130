import styled from 'styled-components'
import Stack from '../../Shared/Stack/Stack'
import { Field } from 'formik'

export const Root = styled(Stack)`
  max-width: 450px;
  margin: 0 auto;
`

export const CheckBox = styled(Field)`
  height: 25px;
  width: 25px;
`
