import { Root } from '../Home/Home.styled'
import UserForm from '../UserForm/UserForm'

const FormPage = () => {
  return (
    <Root>
      <UserForm />
    </Root>
  )
}

export default FormPage
