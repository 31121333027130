import { FunctionComponent } from 'react'
import { Icon, IconProps } from './Icon'

export const ErrorIcon: FunctionComponent<IconProps> = ({ className, size }) => (
  <Icon className={className} size={size}>
    <path
      fillRule='evenodd'
      d='M0 10C0 4.481 4.48 0 10 0C15.53 0 20 4.481 20 10C20 15.521 15.53 20 10 20C4.48 20 0 15.521 0 10ZM9.12 6.21001C9.12 5.73101 9.52 5.33 10 5.33C10.48 5.33 10.87 5.73101 10.87 6.21001V10.63C10.87 11.111 10.48 11.5 10 11.5C9.52 11.5 9.12 11.111 9.12 10.63V6.21001ZM10.01 14.681C9.52 14.681 9.13 14.281 9.13 13.801C9.13 13.321 9.52 12.931 10 12.931C10.49 12.931 10.88 13.321 10.88 13.801C10.88 14.281 10.49 14.681 10.01 14.681Z'
      fill='currentColor'
    />
  </Icon>
)
