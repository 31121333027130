import styled from 'styled-components'
import { Button } from '@mui/material'
import { Formik } from 'formik'

export const Root = styled(Formik)``

export const ButtonDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 35px;
  max-width: 450px;

  margin: 35px auto;
`

export const LaxLabButton = styled(Button)`
  width: -webkit-fill-available;
`
