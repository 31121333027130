import styled, { css } from 'styled-components'
import { CheckIcon } from '../Icons/CheckIcon'
import { ErrorIcon } from '../Icons/ErrorIcon'
import { primaries, neutrals, reds, greens } from '../../../assets/colors'

const minimizedStyles = css`
  & {
    padding: 24px 16px 8px 16px;

    ~ label {
      transform: translate(0, -8px) scale(0.706); /* Scale from 17px to 12px. */
    }
  }
`

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

// Cleave doesn't support a `className` prop directly, so instead we wrap it and
// style the input element.
export const Input = styled.div<{
  $valid?: boolean
  $invalid?: boolean
  $placeholder?: boolean
  $label?: string
}>(
  ({ theme, $valid, $invalid, $placeholder, $label }) => css`
    position: relative;

    input {
      appearance: none;
      background: '#f5f8fb';
      border-radius: 12px;
      border: 1px solid #f5f8fb;
      color: '#192b34';
      display: block;
      font-size: 16px;
      font-weight: 300;
      height: 56px;
      letter-spacing: 0.005em;
      padding: 16px;
      transition: border-color 0.15s ease-in-out;
      width: 100%;

      &::placeholder {
        color: #517486;
      }

      /* Adjust padding and shrink the label whenever the field is in focus, the
      placeholder is not being shown, the field has been autofilled, or there is
      a non-empty placeholder. */
      ${$label &&
    css`
        &:focus,
        &:not(:placeholder-shown),
        &:-webkit-autofill {
          ${minimizedStyles}
        }
        ${$placeholder && minimizedStyles}
      `}

      &:hover {
        background-color: ${primaries.P50};
      }

      &:focus {
        background-color: ${primaries.P50};
        border-color: ${primaries.P800};
        outline: none;
      }

      &:disabled {
        background: ${primaries.P00};
        color: ${primaries.P200};

        &::placeholder {
          color: ${primaries.P200};
        }
      }

      ${($valid || $invalid) &&
    css`
        background-color: ${neutrals.N000};
        padding-right: 48px;
      `}

      ${$valid &&
    css`
        border-color: ${greens.G600};
      `}

      ${$invalid &&
    css`
        border-color: ${reds.R500};
      `}
    }
  `,
)

export const Label = styled.label<{
  $isInvalid?: boolean
  $isDisabled?: boolean
}>(
  ({ $isInvalid, $isDisabled }) => css`
    color: ${primaries.P500};
    font-size: 17px;
    font-weight: 300;
    left: 16px;
    margin: 0;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    right: 0;
    text-align: left;
    text-overflow: ellipsis;
    text-transform: uppercase;
    top: 17px;
    transform-origin: top left;
    transition: all 0.2s ease-out;
    white-space: nowrap;

    ${$isInvalid &&
    css`
      color: ${reds.R500};
    `}

    ${$isDisabled &&
    css`
      color: ${primaries.P100};
    `}
  `,
)

export const ValidIcon = styled(CheckIcon)`
    color: ${greens.G600};
    position: absolute;
    right: 16px;
    top: calc(50% - 10px); /* Vertically centered. */
`;

export const InvalidIcon = styled(ErrorIcon)`
color: ${reds.R500};
position: absolute;
right: 16px;
top: calc(50% - 10px); /* Vertically centered. */
`;

export const Note = styled.span<{ $hasError?: boolean }>(
  ({ $hasError }) => css`
color: ${$hasError ? reds.R500 : '#898989'};
padding-left: 16px;
padding-top: 4px;
text-align: left;
font-size: 18px;
`,
)
