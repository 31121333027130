import { StepIconRoot } from './Stepper.styled'

import PersonIcon from '@mui/icons-material/Person'
import SchoolIcon from '@mui/icons-material/School'
import { StepIconProps } from '@mui/material/StepIcon'
import { SvgIcon, SvgIconProps } from '@mui/material'

const LacrosseIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width='1200pt'
        height='1200pt'
        version='1.1'
        viewBox='0 0 1200 1200'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='m770.17 606.63c39.797 0 71.73-31.984 90.43-50.684 29.492-29.445 53.125-32.812 83.105-37.109v-0.003906c42.184-2.7461 81.855-21.012 111.38-51.27 80.957-80.957 80.957-166.55 0-247.51-80.91-80.91-166.5-81.008-247.51 0-30.258 29.52-48.52 69.195-51.27 111.38-4.2969 29.98-7.668 53.613-37.109 83.105-25.863 25.82-76.77 77.023-33.559 139.61l-85.629 85.637-85.594-85.594c43.324-62.492-7.6992-113.8-33.598-139.65-29.445-29.492-32.812-53.125-37.109-83.105-2.7461-42.184-21.012-81.855-51.27-111.38-81-81.008-166.6-80.91-247.51 0-80.957 80.957-80.957 166.55 0 247.51 29.52 30.258 69.191 48.52 111.38 51.27 29.98 4.2969 53.613 7.668 83.105 37.109 18.652 18.703 50.539 50.637 90.375 50.637 17.805-0.41406 35.039-6.3516 49.32-16.988l85.547 85.543-322.75 322.75c-6.3164 6.3164-8.7812 15.523-6.4688 24.148 2.3125 8.6289 9.0508 15.367 17.68 17.68 8.6289 2.3125 17.832-0.15625 24.148-6.4727l322.75-322.76 322.76 322.76c4.6641 4.7969 11.055 7.5234 17.746 7.5703 6.6914 0.046876 13.121-2.5898 17.852-7.3203 4.7344-4.7305 7.3711-11.16 7.3242-17.852-0.046876-6.6914-2.7734-13.086-7.5703-17.75l-322.76-322.76 85.488-85.484c14.273 10.652 31.52 16.59 49.328 16.98z'
          fill='#fff'
        />
      </svg>
    </SvgIcon>
  )
}

const StepIcon = (props: StepIconProps) => {
  const { active, completed, className } = props

  const icons: { [index: string]: React.ReactElement } = {
    1: <PersonIcon />,
    2: <SchoolIcon />,
    3: <LacrosseIcon />,
  }

  return (
    <StepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </StepIconRoot>
  )
}

export default StepIcon
