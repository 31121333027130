import styled from 'styled-components'
import { Container } from 'react-bootstrap'

export const Root = styled(Container)`
  padding-top: 50px;
  padding-bottom: 50px;
  @media (max-width: 768px) {
    padding: 50px 24px;
  }
`

export const Spacer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  @media (max-width: 768px) {
    gap: 40px;
  }
`

export const Title = styled.h1`
  text-align: center;
  font-size: 32px;
  color: white;

  @media (max-width: 768px) {
    font-size: 24px;
  }
`

export const Description = styled.span`
  font-size: large;
  @media (max-width: 768px) {
    font-size: 13px;
  }
  text-align: center;
  color: white;
`

export const HomeLogo = styled.img`
width: 30%;
height: auto;
@media (max-width: 768px) {
  width: 75%;
  height: auto;
}
`
export const LogoDiv = styled.div`
width: 100%;
display: flex;
justify-content: center;
`