import { NavLink } from 'react-router-dom'
import { Description, Root, Title, Spacer, HomeLogo, LogoDiv } from './Home.styled'
import { LaxLabButton } from '../UserForm/UserForm.styled'
import LaxLabLogo from '../../assets/lax-lab-logo.png'

const Home = () => {
  return (
    <Root>
      <LogoDiv>
        <HomeLogo src={LaxLabLogo} alt="Lax Lab App" />
      </LogoDiv>
      <Spacer>
        <Title>
          You are just a few steps away from getting your custom workouts from the Lax Lab App!
        </Title>
        <Description>
          The Lax Lab is the premier app for elevating your game in lacrosse. We provide you with
          the highest quality of lacrosse instruction in the world, on a platform that is built for
          taking the thinking out of training. No more questioning what drills to do, rep count, or
          how long to work out for. Your opportunity and time to improve is limited; make it count.
        </Description>
        <Description>
          At the end of this form, you will be given the opportunity to join us. Your custom
          workouts will include three weekly workouts (12 monthly) designed to enhance your skills,
          such as dodging, shooting, and footwork. If you are interested in more than drills and
          would like to work with us more on your film, mindset, recruiting, and more, please email
          us at info@thelaxlab.com
        </Description>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <NavLink to='/form'>
            <LaxLabButton variant='contained' size='large' style={{ width: '250px' }}>
              Go to form
            </LaxLabButton>
          </NavLink>
        </div>
      </Spacer>
    </Root>
  )
}

export default Home